import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { User } from '@src/app/core/api';

export const userFeatureKey = 'user';

export interface State {
  checked: boolean;
  user: Pick<User, 'id' | 'email' | 'role'> | null | any;
  accessToken: string | null;
}

export const initialState: State = {
  checked: false,
  user: null,
  accessToken: '',
};

export const reducer = createReducer(
  initialState,
  on(
    AuthActions.loginSuccess,
    (state, { user , accessToken}): State => ({ ...state, user, accessToken }),
  ),
  on(
    AuthActions.loginCheckSuccess,
    (state, { user }): State => ({ ...state, user, checked: true }),
  ),
  on(
    AuthActions.logout,
    (): State => ({
      ...initialState,
      checked: true,
    }),
  ),
);

export const getUser = (state: State) => state.user;
export const getAccessToken = (state: State) => state.accessToken;
