import { Injectable, Injector } from '@angular/core';

import { User } from '@app/core/models/user.model';
import { HttpService } from '@app/core/services/http.service';
import { trimValue } from '@app/core/shared/helpers/trim-value';
import { BehaviorSubject, Subject, of, throwError } from 'rxjs';
import { catchError, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { SystemDefaults } from '../models/systemDefaults.model';
import { CURRENT_USER_DATA } from '../constants';
import { AuthRepository } from '../repositories/auth.repository';
import { ApiResponseInterface } from '../interfaces';
import { LocalStorageService } from '@src/app/core/services/local-storage.service';
import { AUTH_FAILED_REQUEST_CONFLICT_STATUS } from '@src/app/core/constants/system.constant';
import { AuthActions } from '@src/app/features/auth/core/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends HttpService {
  private errors = [];
  public loggedIn = false;
  public userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public systemDefaultsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  private _onDestroy$: Subject<void> = new Subject<void>();

  /**
   * Initializes the constructor of the class with the provided injector.
   *
   * @param {Injector} injector - The injector used to retrieve dependencies.
   */
  constructor(
    injector: Injector,
    private authRepository: AuthRepository,
    private localStorageService: LocalStorageService,
    private store: Store,
  ) {
    super(injector);
    this.setUserDetails();
    this.setSystemDefaults();
  }

  /**
   * Sets the user details based on the provided user object or retrieves it from local storage.
   *
   * @param {any} user - The user object to set as the details. If not provided, it will be retrieved from local storage.
   * @return {void} This function does not return a value.
   */
  setUserDetails(user: any = this.localService.get(CURRENT_USER_DATA)): void {
    if (user != null) {
      this.loggedIn = true;
      this.user = new User(user || {});
      this.userSubject.next(this.user);
    }
  }

  /**
   * Sets the system defaults based on the provided systemDefaults object or retrieves it from local storage.
   *
   * @param {any} systemDefaults - The systemDefaults object to set as the defaults. If not provided, it will be retrieved from local storage.
   * @return {void} This function does not return a value.
   */
  setSystemDefaults(systemDefaults: any = this.localService.get('sid-system-defaults')): void {
    if (systemDefaults != null) {
      this.systemDefaults = new SystemDefaults(systemDefaults || {});
      this.systemDefaultsSubject.next(this.systemDefaults);
    }
  }

  /**
   * Logs in the user by making a GET request to the GET_USER_DETAILS endpoint.
   * If the response status is SUCCESS_STATUS and there is a response body,
   * it makes another GET request to the GET_DEFAULTS_BASE_URL endpoint.
   * If both requests are successful, it sets the user details and system defaults
   * in the local session and returns the user response.
   * If the response status is not SUCCESS_STATUS or there is no response body,
   * it shows a warning message and returns null.
   * If any error occurs during the requests, it throws an error.
   *
   * @return {Observable<any>} An observable that emits the user response or null.
   */
  login() {
    return this.authRepository.getUserDetails().pipe(
      switchMap((response: any): any => {
        if (response?.status === this.constantList.SUCCESS_STATUS) {
          if (!response.body) {
            this.showMessage(`User doesn't exists!`, 'warning');
            return of(null);
          }
          // return response.body;
          return this.authRepository.getBaseURL().pipe(
            map((systemDefaultsResponse: any) => {
              return { userResponse: response.body, systemDefaultsResponse };
            }),
            catchError(err => {
              return throwError(() => this.handleErrorMessages(err));
            }),
          );
        }
        return null;
      }),
      tap(({ userResponse, systemDefaultsResponse }: any) => {
        if (userResponse) {
          this.setLocalSession(userResponse as any, systemDefaultsResponse as any);
          this.loggedIn = true;
          return userResponse;
        }
        this.loggedIn = false;
        return null;
      }),
      shareReplay(1),
      catchError(err => throwError(() => this.handleErrorMessages(err))),
    );
  }

  resetToken() {
    if (this.localStorageService.getToken(true)) {
      this.logout();
      return;
    }

    this.authRepository.resetToken().subscribe({
      next: (response: any) => {
        console.log('response', response);
        if ((response.status as any) === this.constantList.AUTH_FAILED_REQUEST_CONFLICT_STATUS) {
          this.logout();
        }
        if (response.accessToken) this.localStorageService.setToken(String(response.accessToken));
      },
      error: (err: any) => {
        this.logout();
        console.error('An error occurred resetToken :', err);
      },
    });
  }

  /**
   * Logs out the user by making a GET request to the LOGOUT endpoint.
   * It clears the local storage, sets the user details and system defaults to null,
   * clears the CURRENT_USER_DATA key in the local storage, and redirects the user to the '/auth/login' page.
   *
   * @return {void} This function does not return a value.
   */
  public logout() {
    console.log('logout auth services');
    this.store.dispatch(AuthActions.logout());
    this.localStorageService.clearDataInLocalStorage();

    setTimeout(() => {
      if (localStorage.getItem('returnUrl')) {
        localStorage.removeItem('returnUrl');
      }
      // this.setRedirectUrl();
      localStorage.setItem(CURRENT_USER_DATA, '');
      this.setUserDetails(null);
      this.setSystemDefaults(null);
      this.localService.clearLocalStorageExceptOneKey(['sid-app-config', 'language']);
      this._onDestroy$.next();
      this._onDestroy$.complete();
      window.location.replace('/auth/login');
    }, 500);
    // this.authRepository.logout().subscribe({
    //   /**
    //    * Executes a callback function after a delay of 500 milliseconds.
    //    * Removes the 'returnUrl' item from the local storage if it exists.
    //    * Sets the CURRENT_USER_DATA item in the local storage to an empty string.
    //    * Sets the user details and system defaults to null.
    //    * Clears the local storage except for the 'sid-app-config' and 'language' keys.
    //    * Emits and completes the `_onDestroy$` subject.
    //    * Redirects the user to the '/auth/login' page.
    //    *
    //    * @return {void} This function does not return a value.
    //    */
    //   complete: () => {
    //     setTimeout(() => {
    //       if (localStorage.getItem('returnUrl')) {
    //         localStorage.removeItem('returnUrl');
    //       }
    //       // this.setRedirectUrl();
    //       localStorage.setItem(CURRENT_USER_DATA, '');
    //       this.setUserDetails(null);
    //       this.setSystemDefaults(null);
    //       this.localService.clearLocalStorageExceptOneKey(['sid-app-config', 'language']);
    //       this._onDestroy$.next();
    //       this._onDestroy$.complete();
    //       window.location.replace('/auth/login');
    //     }, 500);
    //   },
    // });
  }

  /**
   * Sets the redirect URL based on the current URL and user session.
   *
   * @return {void} This function does not return a value.
   */
  setRedirectUrl() {
    const currentUrl = this.router.url;
    if (currentUrl.includes('no-access')) {
      return;
    }
    if (currentUrl === '/' && window.location.pathname) {
      localStorage.setItem(
        'returnUrl',
        JSON.stringify({
          url: window.location.pathname,
          time: new Date().getTime(),
          id:
            (localStorage.getItem(CURRENT_USER_DATA) &&
              JSON.parse(localStorage.getItem(CURRENT_USER_DATA) || 'null').employeeId) ||
            'notFound',
        }),
      );
    } else {
      if (!currentUrl.includes('no-access') && !currentUrl.includes('otp')) {
        localStorage.setItem(
          'returnUrl',
          JSON.stringify({
            url: currentUrl,
            time: new Date().getTime(),
            id:
              (localStorage.getItem(CURRENT_USER_DATA) &&
                JSON.parse(localStorage.getItem(CURRENT_USER_DATA) || 'null').employeeId) ||
              'notFound',
          }),
        );
      }
    }
  }

  /**
   * Retrieves the current URL and makes a GET request to the HOW_I_AM endpoint.
   * If the response contains a body, it sets the CURRENT_USER_DATA key in the local storage with the response body
   * and calls the setUserDetails() method.
   *
   * @return {void} This function does not return a value.
   */
  public howIAm() {
    const currentUrl = this.router.url;
    this.authRepository
      .getUserDetails()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({
        /**
         * Handles the next response from the server.
         *
         * @param {any} res - The response object from the server.
         * @return {void} This function does not return anything.
         */
        next: (response: unknown) => {
          const res = response as ApiResponseInterface<any>;

          if (res.body) {
            // console.log(res.body);
            this.localService.setDataInLocalStorage({ key: CURRENT_USER_DATA, value: res.body }); ///
            this.setUserDetails();
          }
        },
      });
  }

  /**
   * Checks if the user is logged in by retrieving the CURRENT_USER_DATA key from local storage.
   *
   * @return {boolean} Returns true if the CURRENT_USER_DATA key exists in local storage, false otherwise.
   */
  public isLoggedIn(): boolean {
    return !!this.localService.get(CURRENT_USER_DATA);
  }

  /**
   * the following method will store info in the local session
   * @param authResult
   */
  setLocalSession(authResult: any, systemDefaultsResponse: any) {
    // if (authResult?.profilePicture) {
    //   authResult.profilePicture = getLastModifiedImage('lastModified', authResult.profilePicture);
    // }
    trimValue(authResult.firstName);
    trimValue(authResult.lastName);
    this.localService.set({ key: 'sid-permissions', value: authResult.permissions || [] });
    this.updateUserDetails(authResult);
    this.updateSystemDefaults(systemDefaultsResponse.body);
  }

  /**
   * Updates the user details in the local storage and sets the user details.
   *
   * @param {any} user - The user object to update the details with. If not provided, the current user object is used.
   * @return {void} This function does not return a value.
   */
  updateUserDetails(user: any = this.user): void {
    user['expiry_date'] = new Date();
    this.localService.setDataInLocalStorage({ key: CURRENT_USER_DATA, value: user }); //
    this.setUserDetails(user);
  }

  /**
   * Updates the system defaults by setting the provided systemDefaults object in local storage and updating the systemDefaultsSubject.
   *
   * @param {any} systemDefaults - The systemDefaults object to be set in local storage. If not provided, the current systemDefaults object will be used.
   * @return {void} This function does not return a value.
   */
  updateSystemDefaults(systemDefaults: any = this.systemDefaults): void {
    this.localService.setDataInLocalStorage({
      key: 'sid-system-defaults',
      value: systemDefaults,
    });
    this.setSystemDefaults(systemDefaults);
  }

  /**
   * Checks if the user has the specified permission.
   *
   * @param {string} permission - The permission to check.
   * @return {boolean} Returns true if the user has the permission, false otherwise.
   */
  checkPermission(permission: string | string[]): boolean | void {
    const userData = this.localService.get(CURRENT_USER_DATA);
    if (userData?.permissions?.length === 0) {
      return true;
    } else if (Array.isArray(permission)) {
      for (let index = 0; index < permission.length; index++) {
        const element = permission[index];
        if (userData?.permissions?.includes(element)) {
          return true;
        }
      }
    } else {
      return userData?.permissions?.includes(permission) || false;
    }
  }

  /**
   * Returns a boolean indicating whether the server is running or not.
   *
   * @return {boolean} A boolean value indicating whether the server is running or not.
   */
  public get isServer(): boolean {
    return !this.localService.isBrowser;
  }

  /**
   * Returns a boolean indicating whether the current environment is a browser.
   *
   * @return {boolean} True if the current environment is a browser, false otherwise.
   */
  public get isBrowser(): boolean {
    return this.localService.isBrowser;
  }

  // login(body: { email: any, password: any }) {
  //   return this.requestEntity(
  //     'POST',
  //     SIGNIN,
  //     body,
  //   )
  //     .pipe(
  //       map((response: any) => {
  //         if ((response.status as any) === this.constantList.SUCCESS_STATUS) {
  //           if (!response.body) {
  //             this.showMessage(`User doesn't exists!`, 'warning');
  //           }
  //         }
  //         this.clearErrors();
  //         return null;
  //       }),
  //       tap(authResult => {
  //         if (authResult) {
  //           this.loggedIn = true;
  //           return authResult;
  //         }
  //         this.loggedIn = false;
  //         return null;
  //       }),
  //       shareReplay(1),
  //       catchError(err => throwError(() => this.handleErrorMessages(err)))
  //     );
  // }

  // public logout() {
  //   this.requestEntity(
  //     'DELETE',
  //     LOGOUT,
  //     null,
  //     this.formDataHeaders,
  //     false,
  //   ).pipe(
  //    takeUntilDestroyed(),
  //   ).subscribe({
  //     complete: () => {
  //       this.localService.clearDataInLocalStorage();
  //       window.location.replace('/auth/login');
  //     }
  //   });
  // }

  // public isLoggedIn(): boolean {
  //   // return this.localService.getToken();
  //   return true
  // }

  // public clearErrors() {
  //   this.errors = [];
  // }
}
